
import { computed, defineComponent, onBeforeMount, onMounted, reactive, ref, unref } from 'vue'
import AccountSummary from '@/views/crafted/mc-superadmin/Accounts/AccountSummary.vue'
import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb'
import Swal from 'sweetalert2/dist/sweetalert2.js'
import { Actions, Mutations, MutationsActions } from '@/store/enums/StoreEnums'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { Account } from '@/store/modules/AccountModule'
import { CompaniesPayload } from '@/store/modules/CompanyModule'
import _ from 'lodash'

export interface NewAccount {
    firstName: string,
    lastName: string,
    email: string,
    phone?: string,
    role: number,
    company?: string,
    type?: string
}

export default defineComponent({
  name: 'add-account',
  components: {
    AccountSummary
  },
  setup () {
    onMounted(() => {
      setCurrentPageBreadcrumbs('Add Account', ['Accounts'])
    })

    const store = useStore()
    const router = useRouter()
    const formRef = ref<null | HTMLFormElement>(null)
    const newTargetModalRef = ref<null | HTMLElement>(null)
    const loading = ref(false)
    const account = ref(computed(() => store.getters.getAccount)) as unknown as Account

    const uuid = router.currentRoute.value.params.id
    onBeforeMount(() => {
      store.dispatch(MutationsActions.API_GET_MUTATE_ACCOUNT_BY_UUID, uuid)
    })
    const targetData = reactive({
      accountUuid: '',
      email: '',
      firstName: '',
      lastName: '',
      phone: '',
      company: { uuid: '', name: '' }
    }) as Account

    const rules = computed(() => {
      return {
        firstName: [
          {
            required: true,
            message: 'Please input account first name',
            trigger: 'blur'
          }
        ],
        lastName: [
          {
            required: true,
            message: 'Please input account first name',
            trigger: 'blur'
          }
        ],
        email: [
          {
            required: true,
            message: 'Please input account email',
            trigger: 'blur'
          }
        ],
        roleId: [
          {
            required: true,
            message: 'Please select the role',
            trigger: 'blur'
          }
        ]
      }
    })

    const submit = async (values) => {
      if (!formRef.value) {
        return
      }
      let formValidation = false
      formRef.value.validate((valid) => {
        if (!valid) {
          formValidation = false
          return false
        }
        loading.value = true
        formValidation = true
      })
      if (!formValidation) {
        await store.commit(Mutations.SET_COMPANY_ERRORS, {})

        Swal.fire({
          text: 'Sorry, looks like there are some errors detected, please try again.',
          icon: 'error',
          buttonsStyling: false,
          confirmButtonText: 'Ok, got it!',
          customClass: {
            confirmButton: 'btn btn-primary'
          }
        })
        loading.value = false

        return
      }

      const payload = {
        firstName: unref(account).firstName,
        lastName: unref(account).lastName,
        email: unref(account).email,
        phone: unref(account).phone,
        roleId: unref(account).roleId
      }

      const uuid = router.currentRoute.value.params.id
      const accountPayload = { accountUuid: uuid, accountDetails: payload }

      store.dispatch(Actions.API_UPDATE_ACCOUNT, accountPayload)
        .then(() => {
          Swal.fire({
            text: 'You have successfully updated the account ' + payload.firstName + ' ' + payload.lastName,
            icon: 'success',
            buttonsStyling: false,
            confirmButtonText: 'Ok, got it!',
            customClass: {
              confirmButton: 'btn fw-bold btn-light-primary'
            }
          }).then(function () {
            loading.value = false
            // Go to accounts page after successfully login
            router.push({ name: 'accounts' })
          })
        }).catch(() => {
          loading.value = false
        })
    }

    const companiesAsOptions = computed(() => store.getters.getCompaniesAsOptions)
    const remoteMethod = (query: string) => {
      if (query !== '') {
        loading.value = true
        // clear the initial search results
        store.commit(Mutations.SET_COMPANIES, [])
        setTimeout(() => {
          loading.value = false
          const filter: CompaniesPayload = { filter: { limit: 100, offset: 0, label: query } }
          store.dispatch(MutationsActions.API_GET_MUTATE_COMPANIES, filter)
        }, 200)
      } else {
        const filter: CompaniesPayload = { filter: { limit: 100, offset: 0 } }
        store.dispatch(MutationsActions.API_GET_MUTATE_COMPANIES, filter)
      }
    }

    const onSelectFocused = () => {
      // on focus it should search for existing companies
      const filter: CompaniesPayload = { filter: { limit: 100, offset: 0 } }
      store.dispatch(MutationsActions.API_GET_MUTATE_COMPANIES, filter)
    }

    const onCompanySelectorChanged = (value) => {
      // set the name of the company to be used inside the summary
      targetData.company!.name = companiesAsOptions.value.find(company => company.value === value).label
    }
    const isObjectEmpty = (object: object): boolean => {
      return _.isEmpty(object)
    }

    return {
      companiesAsOptions,
      targetData,
      loading,
      formRef,
      rules,
      newTargetModalRef,
      account,
      remoteMethod,
      submit,
      onSelectFocused,
      onCompanySelectorChanged,
      isObjectEmpty
    }
  }
})
